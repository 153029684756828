import React from 'react';

import { Container, Typography, Card, Button } from '@/components';
import { Each } from '@/components/helpers';

const BlockOverflowingCards = ({ title, title_variant, title_style_variant, contact_banner = true, cards, button_group }) => {
    return (
        <>
            <Container>
                {title && (
                    <Typography variant={title_variant || 'h2'} styleVariant={title_style_variant || null} className='bg-text-gradient bg-clip-text text-transparent max-w-2xl mx-auto text-start md:text-center mb-8 md:mb-16'>
                        {title}
                    </Typography>
                )}
                
                {cards && (
                    <Each of={cards} render={(card, index) => (
                        <Card type={card.type} stars={card.stars} title={card.title} text={card.text} name={card.name} items={card.items} className={`${(!contact_banner && index === cards.length - 1) ? '' : (contact_banner && index === cards.length - 1) ? 'pb-24' : 'pb-36'} ${index !== 0 ? '-mt-28' : ''}`} />
                    )} />
                )}
            </Container>

            {contact_banner && (
                <div className='bg-contact-banner bg-cover bg-no-repeat -mt-16 py-8'>
                    <Container className='flex flex-wrap gap-3 justify-between'>
                        <Typography variant='h2' className='text-white'>
                            Wij zijn 24/7 bereikbaar
                        </Typography>

                        <div className='flex flex-col gap-2'>
                            <Button type='external' external_url='tel:0657695723' label='06 576 957 23' color='secondary' variant='solid' icon='Phone' />
                            <Button type='external' external_url='mailto:info@rioolservicevanegmond.nl' label='Mailen' color='white' variant='bordered' icon='Mail' />
                            <Typography variant='p' className='text-white-accent text-sm'>
                                info@rioolservicevanegmond.nl
                            </Typography>
                        </div>
                    </Container>
                </div>
            )}

            {button_group && (
                <div className="w-fit mx-auto py-8">
                    {button_group.buttons && button_group.buttons.length > 0 && (
                        <div className="flex flex-wrap gap-2 md:gap-5">
                            <Each of={button_group.buttons} render={(button, index) => (
                                <Button type={button.block_button_id.type} add_link_text={button.block_button_id.add_link_text} link_text={button.block_button_id.link_text} page={button.block_button_id.page} post={button.block_button_id.post} external_url={button.block_button_id.external_url} label={button.block_button_id.label} color={button.block_button_id.color} variant={button.block_button_id.variant} icon={button.block_button_id.icon} />
                            )} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default BlockOverflowingCards;