'use client';

import React, { useState, useEffect } from 'react';

import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import { createEntry } from '@/lib/helpers/directus';
import { contactFormSchema, appointmentFormSchema } from '@/lib/helpers/schemas';

import { Button, Textarea, Input, Typography } from '@/components';
import { Each } from '@/components/helpers';

const Form = ({ form }) => {
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [submitError, setSubmitError] = useState('');

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState([]);


    const handleChanges = async (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    // Set errors live
    useEffect(() => {
        if (Object.keys(values).length === 0) return;

        // This if will only validate when the form is submitted with errors
        if (errors && errors.length > 0) {
            let schemaErrors = {};

            if (form.key === 'contact') {
                schemaErrors = contactFormSchema.validate(values);
            } else if (form.key === 'afspraak-maken') {
                schemaErrors = appointmentFormSchema.validate(values);
            }

            const validationErrors = schemaErrors.map((error) => {
                return {
                    name: error.path,
                    message: error.message ?? '',
                    expose: error.message
                }
            });

            if (validationErrors && validationErrors.length > 0) {
                setErrors(validationErrors);
            } else {
                setErrors([]);
            }
        }
    }, [values]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let schemaErrors = {};

        if (form.key === 'contact') {
            schemaErrors = contactFormSchema.validate(values);
        } else if (form.key === 'afspraak-maken') {
            schemaErrors = appointmentFormSchema.validate(values);
        }

        
        const validationErrors = schemaErrors.map((error) => {
            return {
                name: error.path,
                message: error.message ?? '',
                expose: error.message
            }
        });

        if (validationErrors && validationErrors.length > 0) {
            setRefreshReCaptcha(!refreshReCaptcha);

            setErrors(validationErrors);

            return;
        }

        createEntry(e.target.id, form.key, values, token).then((res) => {
            if (form.on_success === 'message') {
                e.target.reset();
    
                setSubmitError('');
                setSuccessMessage(form.success_message);
            } else if (form.on_success === 'redirect') {
                window.location.href = form.redirect_url;
            }
        }).catch((err) => {
            setRefreshReCaptcha(!refreshReCaptcha);

            console.error('Form error', err);

            setSuccessMessage('');
            setSubmitError(err);
        });
    };

    const setTokenFunc = (getToken) => {
        if (!token) {
            setToken(getToken);
        }
    };

    return (
        <>
            {values && Object.keys(values).length > 0 && (
                <Each of={Object.keys(values)} render={(key, index) => (
                    <span key={index} className='hidden'>
                        {key}: {values[key]}
                    </span>
                )} />
            )}

            <form id={form.id} onSubmit={handleSubmit}>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_APP_RECAPTCHA_CLIENT_KEY}>
                    <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={setTokenFunc}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                </GoogleReCaptchaProvider>

                <div className="flex flex-wrap gap-4">
                    <Each of={form.schema} render={(field, index) => (
                        <div className={`${field.width === '50' ? 'w-full md:w-1/2-min-2' : 'w-full'}`}>
                            {field.type === 'textarea' ? (
                                <Textarea
                                    id={field.name}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    required={field.validation === 'required'}
                                    onChange={handleChanges}
                                    className='w-full'
                                />
                            ) : field.type === 'select' ? (
                                'Coming soon'
                            ) : (
                                <Input
                                    id={field.name}
                                    type={field.type}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    required={field.validation === 'required'}
                                    onChange={handleChanges}
                                    className='w-full'
                                />
                            )}

                            {errors && errors.length > 0 && (
                                <Each of={errors} render={(error, index) => (
                                    error.name === field.name && (
                                        <Typography variant='p' className='text-red-400 font-semibold'>
                                            {error.message}
                                        </Typography>
                                    )
                                )} />
                            )}
                        </div>
                    )} />
                </div>

                <Button type='submit' label={form.submit_label} color='secondary' variant='solid' className={`ml-auto mt-3 ${errors && errors.length > 0 ? 'pointer-events-none select-none opacity-50' : ''}`} />
            </form>

            {successMessage && (
                <span className={`form-message block mt-5 ${form.message_color}`} dangerouslySetInnerHTML={{
                    __html: successMessage
                }}></span>
            )}

            {submitError && (
                <span className={`form-message block mt-5 ${form.message_color}`} dangerouslySetInnerHTML={{
                    __html: submitError
                }}></span>
            )}
        </>
    );
}

export default Form;