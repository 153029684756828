import React from 'react';

import Image from 'next/image';

import { CircleCheck } from 'lucide-react';

import { Container, Typography, Button, Trustoo, Form } from '@/components';
import { Each } from '@/components/helpers';

const BlockHero = ({ type = 'centered', title = '', subtitle = '', content = '', reviews, image, image_alignment, button_group, form, params }) => {
    let wrapperClassName = '';
    let className = '';

    if (type === 'centered') {
        className = 'flex flex-col items-start md:items-center justify-center text-start md:text-center';
    } else if (type === 'right') {
        wrapperClassName = 'flex flex-col-reverse md:flex-row gap-8 md:gap-16';
        className = 'flex flex-col md:w-2/3';
    }

    return (
        <div className='bg-radial-primary pt-28 md:pt-36 pb-24 md:pb-28 relative'>
            <Container className={`relative z-10 mb-16 ${wrapperClassName}`}>
                {image && type === 'right' && (
                    <div className='max-w-3/5 md:w-1/3'>
                        <Image src={`${params.globals.assets_url || ''}/${image}`} width={500} height={500} className={`max-h-4/5 rounded-3xl object-cover ${image_alignment === 'left' ? 'object-left' : image_alignment === 'right' ? 'object-right' : 'object-center'}`} alt={title} />
                    </div>
                )}

                <div className={`${className}`}>
                    {title && (
                        <Typography variant="h1">
                            {title}
                        </Typography>
                    )}

                    {subtitle && (
                        <Typography variant="h2" className='text-white'>
                            {subtitle}
                        </Typography>
                    )}

                    {content && (
                        <Typography variant="p" className='text-white'>
                            {content}
                        </Typography>
                    )}

                    {form && (
                        <div className="mt-5">
                            <Form form={form} />
                        </div>
                    )}

                    <div className={`mt-4 flex flex-col gap-5 justify-center ${image ? '' : 'md:items-center'} items-start`}>
                        {reviews && (
                            <div className="flex flex-col">
                                <Trustoo />

                                <div className={`flex items-start gap-3 ${type === 'centered' ? 'md:justify-center' : ''}`}>
                                    <CircleCheck color='var(--color-green)' size={36} />
                                    <div className="flex flex-col">
                                        <Typography variant='p' styleVariant='h3' className='max-w-xs text-green font-bold m-0'>
                                            24 uursservice
                                        </Typography>
                                        <Typography variant='p' styleVariant='h3' className='max-w-xs text-green font-bold m-0'>
                                            Gelderland!
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}

                        {button_group && button_group.buttons && button_group.buttons.length > 0 && (
                            <div className={`flex flex-col md:flex-row flex-wrap gap-2 md:gap-5 ${button_group.alignment === 'center' ? 'md:mx-auto' : ''}`}>
                                <Each of={button_group.buttons} render={(button, index) => (
                                    <Button type={button.block_button_id.type} add_link_text={button.block_button_id.add_link_text} link_text={button.block_button_id.link_text} page={button.block_button_id.page} post={button.block_button_id.post} external_url={button.block_button_id.external_url} label={button.block_button_id.label} color={button.block_button_id.color} variant={button.block_button_id.variant} icon={button.block_button_id.icon} />
                                )} />
                            </div>
                        )}
                    </div>
                </div>
            </Container>

            {image && type === 'centered' && (
                <div className='max-w-3/5 md:w-1/3 md:h-1/2 absolute top-1/2 -translate-y-1/2 md:top-auto md:bottom-10 md:translate-y-0 right-0'>
                    <Image src={`${params.globals.assets_url || ''}/${image}`} width={500} height={500} className='max-h-full ml-auto object-contain object-right' alt={title} />
                </div>
            )}

            <Image src='images/pipe.svg' width={156} height={154} className='w-28 md:w-36 absolute bottom-0 left-5 md:left-10 lg:left-20 z-0 move-in-from-left' alt='Pipe' />
        </div>
    );
}

export default BlockHero;