import block_alphabet_list from "@/components/blocks/block_alphabet_list";
import block_banner from "@/components/blocks/block_banner";
import block_cards from "@/components/blocks/block_cards";
import block_form_section from "@/components/blocks/block_form_section";
import block_hero from "@/components/blocks/block_hero";
import block_image_text from "@/components/blocks/block_image_text";
import block_overflowing_cards from "@/components/blocks/block_overflowing_cards";
import block_services_slider from "@/components/blocks/block_services_slider";
import block_text_grid from "@/components/blocks/block_text_grid";
import block_text_with_list from "@/components/blocks/block_text_with_list";
import block_text from "@/components/blocks/block_text";
import block_video from "@/components/blocks/block_video";

export {
    block_alphabet_list,
    block_banner,
    block_cards,
    block_form_section,
    block_hero,
    block_image_text,
    block_overflowing_cards,
    block_services_slider,
    block_text_grid,
    block_text_with_list,
    block_text,
    block_video,
}