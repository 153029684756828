import { directus, createItem } from "@/lib/helpers/directus";

export const createEntry = async (formId, formKey, data, token) => {
    return new Promise(async (resolve, reject) => {
        const googleResponse = await fetch('/api/recaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token
            })
        });

        const googleData = await googleResponse.json();
        if (!googleData.success) {
            reject("Er is iets foutgegaan bij het versturen van het formulier. Probeer het later opnieuw.");
            return;
        }

        const date = new Date();
        let dateAfterOneMonth = new Date(date.setMonth(date.getMonth() + 1));

        try {
            const response = await directus.request(createItem('form_entries', {
                form_id: formId,
                data: {
                    form_id: formId,
                    form_key: formKey,
                    ...data,
                    date_to_delete: dateAfterOneMonth
                },
                date_to_delete: dateAfterOneMonth
            }));
    
            resolve('form_success');
        } catch (error) {
            console.error('createEntry error', error);

            reject('Er is iets foutgegaan bij het versturen van het formulier. Probeer het later opnieuw.');
        }
    });
};