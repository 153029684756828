import React from 'react';

import Link from 'next/link';

import * as lucideIcons from 'lucide-react';

import { Typography } from '@/components';
import { Each } from '@/components/helpers';

const BottomNavigation = async ({ items }) => {
    return (
        <div className='flex flex-col md:flex-row gap-8 md:gap-y-0 justify-between'>
            {items && (
                <Each of={items} render={(item, index) => (
                    <div className='h-full flex items-start'>
                        <div className='flex flex-col gap-y-2 h-fit items-left'>
                            {(item.title && item.url) ? (
                                <Link href={item.url} target={item.open_in_new_tab ? '_blank' : '_self'} className='w-fit'>
                                    <Typography variant='h2' styleVariant='h5' className={item.classname ? item.classname : ''}>
                                        {item.title}
                                    </Typography>
                                </Link>
                            ) : item.title ? (
                                <Typography variant='h2' styleVariant='h5' className={item.classname ? item.classname : ''}>
                                    {item.title}
                                </Typography>
                            ) : null}

                            {item.has_children && (
                                <div className='flex flex-col gap-y-2'>
                                    {item.children && (
                                        <Each of={item.children} render={(child, index) => (
                                            child.type === 'url' && child.url ? (
                                                <Link href={child.url} target={child.open_in_new_tab ? '_blank' : '_self'} className='text-base flex gap-x-1 items-center w-fit text-white-accent transition-all duration-300 font-medium opacity-75 hover:opacity-100'>
                                                    {child.icon && (
                                                        <span className='mr-2'>
                                                            {React.createElement(lucideIcons[child.icon], { size: 22 })}
                                                        </span>
                                                    )}

                                                    {child.title && (
                                                        <Typography variant='p' className={child.classname ? child.classname : ''}>
                                                            {child.title}
                                                        </Typography>
                                                    )}
                                                </Link>
                                            ) : child.type === 'page' && child.page ? (
                                                <>
                                                    <Link href={child.page.permalink} target={child.open_in_new_tab ? '_blank' : '_self'} className='text-base flex gap-x-1 items-center w-fit text-white-accent transition-all duration-300 font-medium opacity-75 hover:opacity-100'>
                                                        {child.icon && (
                                                            <span className='mr-2'>
                                                                {React.createElement(lucideIcons[child.icon], { size: 22 })}
                                                            </span>
                                                        )}

                                                        {child.title && (
                                                            <Typography variant='p' className={child.classname ? child.classname : ''}>
                                                                {child.title}
                                                            </Typography>
                                                        )}
                                                    </Link>
                                                </>
                                            ) : (
                                                <div className='text-base flex gap-x-1 items-center w-fit text-white-accent transition-all duration-300 font-medium opacity-75'>
                                                    {child.icon && (
                                                        <span className='mr-2'>
                                                            {React.createElement(lucideIcons[child.icon], { size: 22 })}
                                                        </span>
                                                    )}
                                                    
                                                    {child.title && (
                                                        <Typography variant='p' className={child.classname ? child.classname : ''}>
                                                            {child.title}
                                                        </Typography>
                                                    )}
                                                </div>
                                            )
                                        )} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )} />
            )}
		</div>
    );
}

export default BottomNavigation;