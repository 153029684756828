import React from 'react';

const Container = ({ className = '', children }) => {
    return (
        <div className={`w-container-small sm:w-container mx-auto ${className}`}>
            {children}
        </div>
    );
}

export default Container;