import React from 'react';

import { Container, Typography, Card, Button } from '@/components';
import { Each } from '@/components/helpers';

const BlockCards = ({ title, title_variant, title_style_variant, cards, button_group }) => {
    return (
        <Container>
            {title && (
                <Typography variant={title_variant || 'h2'} styleVariant={title_style_variant || null} className='bg-text-gradient bg-clip-text text-transparent max-w-2xl mx-auto text-start md:text-center mb-8 md:mb-16'>
                    {title}
                </Typography>
            )}
            
            {cards && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <Each of={cards} render={(card, index) => (
                        <Card type={card.type} stars={card.stars} title={card.title} text={card.text} name={card.name} items={card.items} />
                    )} />
                </div>
            )}

            {button_group && (
                <div className="w-fit md:mx-auto py-8">
                    {button_group.buttons && button_group.buttons.length > 0 && (
                        <div className="flex flex-wrap gap-2 md:gap-5">
                            <Each of={button_group.buttons} render={(button, index) => (
                                <Button type={button.block_button_id.type} add_link_text={button.block_button_id.add_link_text} link_text={button.block_button_id.link_text} page={button.block_button_id.page} post={button.block_button_id.post} external_url={button.block_button_id.external_url} label={button.block_button_id.label} color={button.block_button_id.color} variant={button.block_button_id.variant} icon={button.block_button_id.icon} />
                            )} />
                        </div>
                    )}
                </div>
            )}
        </Container>
    );
}

export default BlockCards;