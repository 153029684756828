import { directus, readItems, readItem, readSingleton } from "@/lib/helpers/directus";

export const getItems = async (collection, filterObj) => {
    try {
        const items = await directus.request(readItems(collection, filterObj));

        if (!items.length) {
            return null;
        }

        return items;
    } catch (error) {
        console.error('error', error);

        return null;
    }
};

export const getSingleton = async (singleton, filterObj) => {
    try {
        const item = await directus.request(readSingleton(singleton, filterObj));

        if (!item) {
            return null;
        }

        return item;
    } catch (error) {
        console.error('error', error);

        return null;
    }
};