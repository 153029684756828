import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { Container, BottomNavigation, CopyrightBar } from '@/components';
import { Each } from '@/components/helpers';
import * as blocks from '@/components/blocks';

const Footer = ({ id, footer, params }) => {
    return (
        <footer id={id} className='relative'>
            {footer.blocks && (
                <Each of={footer.blocks} render={(block, index) => (
                    blocks[block.collection] ? (
                        React.createElement(blocks[block.collection], {
                            key: index,
                            ...block.item,
                            params
                        })
                    ) : (
                        console.error(`Component not found: ${block.collection}`),
                        null
                    )
                )} />
            )}

            <div className='h-fit inset-0 bg-radial-primary-300 bg-opacity-90 rounded-t-3xl relative z-20'>
                <Container className='pt-12 flex flex-col gap-y-4'>
                    {footer.items && (
                        <BottomNavigation items={footer.items} />
                    )}

                    <CopyrightBar />
                </Container>
            </div>

            <Link href="https://webbeukers.nl" target='_blank' className="absolute bottom-0 right-0 m-3 z-50">
                <Image width={24} height={17} className='w-6' src="/images/webbeukers-logo.svg" alt="Webbeukers B.V." />
            </Link>
        </footer>
    );
}

export default Footer;