import React from 'react';

import { convertData, getItems } from '@/lib/helpers/directus';
import { generatePathnames } from '@/lib/helpers/pathnameHelper';

import { Container, Typography, AlphabetList, LinkList } from '@/components';

const BlockAlphabetList = async ({ title, type_is_all, type_id, params }) => {
    let type = 'all';
    if (type_is_all) {
        type = 'all';
    } else if (type_id) {
        if (type_id.type_id) {
            type = type_id.type_id;
        } else {
            return (
                <div></div>
            )
        }
    }

    let pathnames = await generatePathnames('all_with_expertise', 'array');
    pathnames = pathnames.filter((pathname) => pathname.expertise === true);

    const locations = await getItems('seo_locations', {
        fields: [
            '*',
            {
                types_id: [
                    '*',
                    {
                        seo_types_id: [
                            '*',
                        ],
                    }
                ]
            }
        ]
    });

    const convertedLocations = await convertData(locations, [
        {
            from: 'types_id.seo_types_id',
            to: 'types',
        }
    ]);

    return (
        <>
            <Container>
                {title && (
                    <Typography variant='h2' className='max-w-xl mx-auto text-center'>
                        {title}
                    </Typography>
                )}

                {convertedLocations && (
                    <AlphabetList type={type} items={convertedLocations} params={params} />
                )}
            </Container>

            <Container className='mt-16'>
                <Typography variant='h2' className='max-w-xl mx-auto text-center'>
                    Zie ook onze andere expertises
                </Typography>

                <div className="mt-12 flex flex-col gap-12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                        <LinkList links={pathnames} />
                    </div>
                </div>
            </Container>
        </>
    );
}

export default BlockAlphabetList;