import React from 'react';

import Image from 'next/image';

import * as blocks from '@/components/blocks';
import { Container, Typography } from '@/components';
import { Each } from '@/components/helpers';

const BlockImageText = ({ image_position = 'left', image, title, text, vertical_alignment = 'start', children, params }) => {
    let imageClassName = 'w-auto md:w-full col-span-1 md:col-span-2 rounded-2xl';

    return (
        <Container className={`grid grid-cols-1 md:grid-cols-5 gap-5 md:gap-20 items-${vertical_alignment}`}>
            {image_position === 'left' && (
                <Image width={416} height={500} src={`${params.globals.assets_url || ''}/${image}`} alt={title} className={imageClassName} />
            )}

            <div className='max-w-lg col-span-1 md:col-span-3'>
                {title && (
                    <Typography variant='h3' styleVariant='h2' className='text-black mb-2'>
                        {title}
                    </Typography>
                )}

                {text && (
                    <Typography variant='p'>
                        {text}
                    </Typography>
                )}

                {children && (
                    <Each of={children} render={(child, index) => (
                        <div className='mt-5'>
                            {blocks[child.collection] ? (
                                React.createElement(blocks[child.collection], {
                                    key: index,
                                    ...child.item,
                                    params,
                                })
                            ) : (
                                console.error(`Component not found: ${child.collection}`),
                                null
                            )}
                        </div>
                    )} />
                )}
            </div>

            {image_position === 'right' && (
                <Image width={416} height={500} src={`${params.globals.assets_url || ''}/${image}`} alt={title} className={imageClassName} />
            )}
        </Container>
    );
}

export default BlockImageText;