import React from 'react';

import * as lucideIcons from 'lucide-react';

import { Typography, Button } from '@/components';
import { Each } from '@/components/helpers';

const BlockTextWithList = ({ title, text, list_type, list, button_group, params }) => {
    return (
        <div>
            {title && (
                <Typography variant='h3' className='text-black mb-2'>
                    {title}
                </Typography>
            )}

            {text && (
                <Typography variant='p'>
                    {text}
                </Typography>
            )}

            {list && (
                <ul className={`${list_type && list_type === 'ordered' ? 'list-decimal ml-6' : list_type && list_type === 'unordered ml-6' ? 'list-disc' : ''} mt-3`}>
                    <Each of={list} render={(item, index) => (
                        <li className={list_type === 'custom' ? 'flex' : ''}>
                            {list_type === 'custom' && index !== list.length - 1 && (
                                <span className='mr-2'>
                                    {React.createElement(lucideIcons[item.icon ?? 'CaseLower'], { color: 'black', size: 22 })}
                                </span>
                            )}

                            <Typography variant='p'>
                                {item.text}
                            </Typography>
                        </li>
                    )} />
                </ul>
            )}

            {button_group && button_group.buttons && button_group.buttons.length > 0 && (
                <div className="flex flex-wrap gap-2 md:gap-5 mt-5">
                    <Each of={button_group.buttons} render={(button, index) => (
                        <Button type={button.block_button_id.type} add_link_text={button.block_button_id.add_link_text} link_text={button.block_button_id.link_text} page={button.block_button_id.page} post={button.block_button_id.post} external_url={button.block_button_id.external_url} label={button.block_button_id.label} color={button.block_button_id.color} variant={button.block_button_id.variant} icon={button.block_button_id.icon} />
                    )} />
                </div>
            )}
        </div>
    );
}

export default BlockTextWithList;