import React from 'react';

import { Container, Typography } from '@/components';
import { Each } from '@/components/helpers';

const BlockTextGrid = ({ text_blocks, params }) => {
    return (
        <Container>
            <div className={`grid grid-cols-1 gap-8 md:grid-cols-2`}>
                <Each of={text_blocks} render={(block, index) => (
                    <div>
                        {block.item.title && (
                            <Typography variant='h2' styleVariant='h3' className='text-black mb-2'>
                                {block.item.title}
                            </Typography>   
                        )}

                        {block.item.text && (
                            <Typography variant='p'>
                                {block.item.text}
                            </Typography>
                        )}
                    </div>
                )} />
            </div>
        </Container>
    );
}

export default BlockTextGrid;