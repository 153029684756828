import React from 'react';

import { Typography } from '@/components';
import { Each } from '@/components/helpers';

const Card = ({ type = 'default', stars, title, text, name, items, className = '' }) => {
    return (
        <div className={`bg-white rounded-2xl shadow-card p-6 break-words ${className}`}>
            {type === 'default' ? (
                <div>
                    {title && (
                        <Typography variant='h3' className='text-black mb-2'>
                            {title}
                        </Typography>
                    )}

                    {text && (
                        <Typography variant='p'>
                            {text}
                        </Typography>
                    )}
                </div>
            ) : type === 'review' ? (
                <h1>Review, to use {'{ stars, text, name }'}</h1>
            ) : type === 'multiple' ? (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-2">
                    {items && (
                        <Each of={items} render={(item, index) => (
                            <div className='
                                border border-transparent
                                border-b-black-10 last:border-b-transparent
                                md:odd:border-r-black-10 md:odd:last:border-r-transparent md:border-b-transparent
                                xl:border-r-black-10 xl:last:border-r-transparent
                                odd:pr-2 pb-2 last:pb-0
                            '>
                                {item.title && (
                                    <Typography variant='h3' className='text-black mb-2'>
                                        {item.title}
                                    </Typography>
                                )}

                                {item.text && (
                                    <Typography variant='p'>
                                        {item.text}
                                    </Typography>
                                )}
                            </div>
                        )} />
                    )}
                </div>
            ) : (
                <h1>No card type specified</h1>
            )}
        </div>
    );
}

export default Card;