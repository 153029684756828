'use client';

import React from 'react';

import Link from 'next/link';

import { Typography } from '@/components';

const LinkList = ({ links }) => {
    return (
        links && links.map((link, index) => (
            <Link key={index} href={link.slug} className='underline w-fit'>
                <Typography variant='p' className='m-0'>
                    {`${link.title}`}
                </Typography>
            </Link>
        ))
    )
}

export default LinkList