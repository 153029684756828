import React from 'react';

import { Container, Typography } from '@/components';

const BlockText = ({ title, text, add_container = false, params }) => {
    return (
        add_container ? (
            <Container>
                {title && (
                    <Typography variant='h2' styleVariant='h4' className='text-black font-bold m-0'>
                        {title}
                    </Typography>
                )}

                {text && (
                    <Typography variant='p' className=''>
                        {text}
                    </Typography>
                )}
            </Container>
        ) : (
            <div>
                {title && (
                    <Typography variant='h2' styleVariant='h4' className='text-black font-bold m-0'>
                        {title}
                    </Typography>
                )}

                {text && (
                    <Typography variant='p' className=''>
                        {text}
                    </Typography>
                )}
            </div>
        )
    );
}

export default BlockText;