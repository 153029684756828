'use client';

import React, { useRef } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { ChevronDown } from 'lucide-react';

import { Typography } from '@/components';
import { Each } from '@/components/helpers';

const getServicesHTML = (services) => {
    return (
		<div className="flex gap-10 items-center scroll-to-left-slow">
            <Each of={services} render={(service, index) => (
                <>
                    <div className="flex items-center gap-2 md:gap-4 w-fit">
                        <Image src='/images/checkbox-primary.svg' width={36} height={36} className='w-6 md:w-9' alt='Checkbox' />

                        {service.text && (
                            <Typography variant="h2" styleVariant='h4' className="text-primary-900 text-nowrap m-0">
                                {service.text}
                            </Typography>
                        )}
                    </div>

                    <div className="border-r-2 border-black h-8 md:h-12 ml-6 md:ml-8"></div>
                </>
            )} />
		</div>
    )
};

const BlockServicesSlider = ({ services }) => {
    const ref = useRef(null);
	
	const pauseAnimation = () => {
		if (ref.current !== null && typeof ref.current !== 'undefined') {
			ref.current.childNodes.forEach((child) => {
				child.style.animationPlayState = 'paused';
			});
		}
	};
	
	const playAnimation = () => {
		if (ref.current !== null && typeof ref.current !== 'undefined') {
			ref.current.childNodes.forEach((child) => {
				child.style.animationPlayState = 'running';
			});
		}
	};

    return (
        <>
            <div className='relative mb-20'>
                {/* <div className="bg-white h-fit flex justify-between gap-10 px-6 md:px-16 py-4 shadow-primary overflow-x-auto">
                    <Each of={services} render={(service, index) => (
                        <>
                            <div className="flex items-center gap-2 md:gap-4 w-fit">
                                <Image src='/images/checkbox-primary.svg' width={36} height={36} className='w-6 md:w-9' alt='Checkbox' />

                                {service.text && (
                                    <Typography variant="h2" styleVariant='h4' className="text-primary-900 text-nowrap m-0">
                                        {service.text}
                                    </Typography>
                                )}
                            </div>

                            {index !== services.length - 1 && (
                                <div className="border-r-2 border-black h-8 md:h-12"></div>
                            )}
                        </>
                    )} />
                </div> */}

                <div ref={ref} className='bg-white h-fit px-6 md:px-16 py-4 shadow-primary mx-auto overflow-x-hidden flex gap-10' onMouseEnter={pauseAnimation} onMouseLeave={playAnimation}>
                    {getServicesHTML(services)}
                    {getServicesHTML(services)}
                    {getServicesHTML(services)}
                </div>
                <div className="w-full flex justify-center absolute">
                    <Link href='#next' className='bg-radial-primary-200 w-16 md:w-24 h-fit rounded-b-3xl'>
                        <ChevronDown size={50} color='white' className='w-8 md:w-12 mx-auto mt-0 md:mt-2 mb-0 md:mb-2 up-and-down' />
                    </Link>
                </div>
            </div>

            <div id="next" className='scroll-mt-20 md:scroll-mt-16'></div>
        </>
    );
}

export default BlockServicesSlider;