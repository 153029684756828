'use client';

import React, { useRef, useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { Menu, X, ChevronDown, ChevronUp } from 'lucide-react';

import { Container, Typography } from '@/components';
import { Each } from '@/components/helpers';

const Header = ({ id, header, params }) => {
    const headerRef = useRef(null);
    const openMenuWrapperRef = useRef(null);
    const openMenuRef = useRef(null);

    const [open, setOpen] = useState(false);

    const handleScroll = (e) => {
        if (headerRef.current) {
            if (window.scrollY > 0) {
                headerRef.current.classList.add('bg-header');
                headerRef.current.classList.add('pt-4');
                headerRef.current.classList.add('h-24');
                headerRef.current.classList.remove('h-20');

                if (openMenuRef.current) {
                    openMenuRef.current.classList.add('pt-4');
                    openMenuRef.current.classList.add('h-24');
                }
            } else {
                headerRef.current.classList.remove('bg-header');
                headerRef.current.classList.remove('pt-4');
                headerRef.current.classList.remove('h-24');
                headerRef.current.classList.add('h-20');

                if (openMenuRef.current) {
                    openMenuRef.current.classList.remove('pt-4');
                    openMenuRef.current.classList.remove('h-24');
                }
            }
        }
    };

    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (open) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [open]);

    const handleOpen = (action) => {
        if (action === 'open') {
            setOpen(true);
        } else {
            hideAllMobileChildItems();

            openMenuWrapperRef.current.classList.add('fade-out');

            setTimeout(() => {
                setOpen(false);
                openMenuWrapperRef.current.classList.remove('fade-out');
            }, 300);
        }
    };

    const hideAllChildItems = (dontHide) => {
        return new Promise(async (resolve, reject) => {
            if (header.items) {
                await Promise.all(header.items.map(async (item, index) => {
                    if (dontHide && dontHide === index) return;

                    const subItem = document.getElementById(`sub-item-${index}`);

                    if (subItem === null) return;

                    subItem.classList.remove('fade-in');
                    subItem.classList.add('fade-out');

                    setTimeout(() => {
                        subItem.classList.add('hidden');
                        subItem.classList.remove('fade-out');
                    }, 300);
                }));
            }

            resolve();
        });
    };

    const handleChildItems = async (action, index) => {
        if (index === 'all') {
            await hideAllChildItems();

            return;
        } else {
            const subItem = document.getElementById(`sub-item-${index}`);
    
            if (subItem === null) return;
    
            if (action === 'open') {
                await hideAllChildItems(index);

                subItem.classList.add('fade-in');
                subItem.classList.remove('hidden');
            } else {
                await hideAllChildItems();
            }
        }
    };

    const hideAllMobileChildItems = (dontHide) => {
        return new Promise(async (resolve, reject) => {
            if (header.items) {
                await Promise.all(header.items.map(async (item, index) => {
                    if (dontHide && dontHide === index) return;

                    const mobileSubItem = document.getElementById(`mobile-sub-item-${index}`);

                    if (mobileSubItem === null) return;

                    mobileSubItem.classList.remove('max-h-[500px]');
                    mobileSubItem.classList.add('max-h-0');

                    const closeIcon = document.getElementById(`close-icon-${index}`);
                    const openIcon = document.getElementById(`open-icon-${index}`);

                    closeIcon.classList.add('hidden');
                    openIcon.classList.remove('hidden');
                }));
            }

            resolve();
        });
    };

    const handleMobileChildItems = async (action, index) => {
        const closeIcon = document.getElementById(`close-icon-${index}`);
        const openIcon = document.getElementById(`open-icon-${index}`);

        const mobileSubItem = document.getElementById(`mobile-sub-item-${index}`);

        if (mobileSubItem === null) return;

        if (action === 'open') {
            await hideAllMobileChildItems(index);

            closeIcon.classList.remove('hidden');
            openIcon.classList.add('hidden');

            mobileSubItem.classList.add('max-h-[500px]');
            mobileSubItem.classList.remove('max-h-0');
        } else {
            await hideAllMobileChildItems();

            closeIcon.classList.add('hidden');
            openIcon.classList.remove('hidden');

            mobileSubItem.classList.remove('max-h-[500px]');
            mobileSubItem.classList.add('max-h-0');
        }
    };

    return (
        <header id={id} ref={headerRef} className='w-full h-20 fixed top-0 z-30 transition-all duration-300 pb-4' onMouseLeave={() => handleChildItems('close', 'all')}>
            <Container className='h-full flex justify-between md:justify-start gap-16 lg:gap-28'>
                {header.logo && (
                    <Link href='/' className='max-w-1/2 px-4 py-2'>
                        <Image width={167} height={48} src={`${params.globals.assets_url || ''}/${header.logo}`} alt={params.globals.tagline} className='h-full object-contain' />
                    </Link>
                )}

                <div className="hidden md:flex flex-wrap items-center md:gap-8 lg:gap-16">
                    {header.items && (
                        <Each of={header.items} render={(item, index) => (
                            <div className='flex flex-col gap-y-2 h-fit items-left relative' onMouseEnter={() => handleChildItems('open', index)}>
                                {item.type === 'url' && item.url ? (
                                    <Link href={item.url} target={item.open_in_new_tab ? '_blank' : '_self'} className='w-fit'>
                                        <Typography variant='p' className='text-white-accent text-sm font-bold uppercase'>
                                            {item.title}
                                        </Typography>
                                    </Link>
                                ) : item.type === 'page' && item.page ? (
                                    <Link href={item.page.permalink} target={item.open_in_new_tab ? '_blank' : '_self'} className='w-fit'>
                                        <Typography variant='p' className='text-white-accent text-sm font-bold uppercase'>
                                            {item.title}
                                        </Typography>
                                    </Link>
                                ) : (
                                    <Typography variant='p' className='text-white-accent text-sm font-bold uppercase'>
                                        {item.title}
                                    </Typography>
                                )}

                                {item.has_children && (
                                    <div id={`sub-item-${index}`} className='hidden absolute top-full left-1/2 -translate-x-1/2 mt-2 bg-primary-800 px-3 py-2 rounded-lg'>
                                        <div className="flex flex-col items-center">
                                            <Each of={item.children} render={(child, index) => (
                                                <div className='flex flex-col gap-y-2 h-fit items-left my-1'>
                                                    {child.type === 'url' && child.url ? (
                                                        <Link href={child.url} target={child.open_in_new_tab ? '_blank' : '_self'} className='w-fit'>
                                                            <Typography variant='p' className='text-white-accent text-sm font-bold uppercase text-nowrap'>
                                                                {child.title}
                                                            </Typography>
                                                        </Link>
                                                    ) : child.type === 'page' && child.page ? (
                                                        <Link href={child.page.permalink} target={child.open_in_new_tab ? '_blank' : '_self'} className='w-fit'>
                                                            <Typography variant='p' className='text-white-accent text-sm font-bold uppercase text-nowrap'>
                                                                {child.title}
                                                            </Typography>
                                                        </Link>
                                                    ) : (
                                                        <Typography variant='p' className='text-white-accent text-sm font-bold uppercase text-nowrap'>
                                                            {child.title}
                                                        </Typography>
                                                    )}
                                                </div>
                                            )} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )} />
                    )}
                </div>

                <div ref={openMenuWrapperRef} className={`${open ? 'block' : 'hidden'} fixed inset-0 bg-radial-primary-400 z-50 ${open ? 'fade-in' : ''}`}>
                    <div ref={openMenuRef} className='h-20 pb-4 transition-all duration-300'>
                        <Container className='h-full flex justify-between md:justify-start gap-16 lg:gap-28'>
                            {header.logo && (
                                <Link href='/' className='max-w-1/2 px-4 py-2'>
                                    <Image width={167} height={48} src={`${params.globals.assets_url || ''}/${header.logo}`} alt={params.globals.tagline} className='h-full object-contain' />
                                </Link>
                            )}

                            <X color='white' size={32} className='my-auto md:hidden transition-all duration-300' onClick={() => handleOpen('close')} />
                        </Container>

                        <Container>
                            <div className="flex flex-col justify-center gap-3 py-16">
                                {header.items && (
                                    <Each of={header.items} render={(item, index) => (
                                        <div className='flex flex-col gap-y-2 h-fit items-left'>
                                            <div className='flex gap-2 items-center mb-4'>
                                                {item.type === 'url' && item.url ? (
                                                    <Link href={item.url} target={item.open_in_new_tab ? '_blank' : '_self'} className='w-fit' onClick={() => handleOpen('close')}>
                                                        <Typography variant='p' styleVariant='h3' className='text-white-accent uppercase m-0'>
                                                            {item.title}
                                                        </Typography>
                                                    </Link>
                                                ) : item.type === 'page' && item.page ? (
                                                    <Link href={item.page.permalink} target={item.open_in_new_tab ? '_blank' : '_self'} className='w-fit' onClick={() => handleOpen('close')}>
                                                        <Typography variant='p' styleVariant='h3' className='text-white-accent uppercase m-0'>
                                                            {item.title}
                                                        </Typography>
                                                    </Link>
                                                ) : (
                                                    <Typography variant='p' styleVariant='h3' className='text-white-accent uppercase m-0' onClick={() => handleOpen('close')}>
                                                        {item.title}
                                                    </Typography>
                                                )}

                                                {item.has_children && (
                                                    <>
                                                        <ChevronDown id={`open-icon-${index}`} color='white' strokeWidth={3} size={30} onClick={() => handleMobileChildItems('open', index)} />
                                                        <ChevronUp id={`close-icon-${index}`} color='white' strokeWidth={3} size={30} className='hidden' onClick={() => handleMobileChildItems('close', index)} />
                                                    </>
                                                )}
                                            </div>

                                            {item.has_children && (
                                                <div id={`mobile-sub-item-${index}`} className='pl-3 transition-all duration-500 origin-top max-h-0 overflow-hidden'>
                                                    <Each of={item.children} render={(child, index) => (
                                                        <div className='flex gap-2 items-center mb-4'>
                                                            {child.type === 'url' && child.url ? (
                                                                <Link href={child.url} target={child.open_in_new_tab ? '_blank' : '_self'} className='w-fit' onClick={() => handleOpen('close')}>
                                                                    <Typography variant='p' styleVariant='h4' className='text-white-accent font-bold uppercase m-0'>
                                                                        {child.title}
                                                                    </Typography>
                                                                </Link>
                                                            ) : child.type === 'page' && child.page ? (
                                                                <Link href={child.page.permalink} target={child.open_in_new_tab ? '_blank' : '_self'} className='w-fit' onClick={() => handleOpen('close')}>
                                                                    <Typography variant='p' styleVariant='h4' className='text-white-accent font-bold uppercase m-0'>
                                                                        {child.title}
                                                                    </Typography>
                                                                </Link>
                                                            ) : (
                                                                <Typography variant='p' styleVariant='h4' className='text-white-accent font-bold uppercase m-0'>
                                                                    {child.title}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    )} />
                                                </div>
                                            )}
                                        </div>
                                    )} />
                                )}
                            </div>
                        </Container>
                    </div>
                </div>
                
                <Menu color='white' size={32} className='my-auto md:hidden' onClick={() => handleOpen('open')} />
            </Container>
        </header>
    );
}

export default Header;