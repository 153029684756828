'use client';

import React, { useEffect, useRef } from 'react';

import Image from 'next/image';

import { Container, Typography, Button } from '@/components';
import { Each } from '@/components/helpers';

const BlockBanner = ({ title, text, image, image_position, button_group, params }) => {
    const imageRef = useRef(null);

    const handleScroll = () => {
        // Check if the image is in the viewport
        if (imageRef.current) {
            const image = imageRef.current;
            const rect = image.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const windowWidth = window.innerWidth || document.documentElement.clientWidth;

            if (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= windowHeight &&
                rect.right <= windowWidth
            ) {
                image.classList.add('move-in-from-bottom');
                image.classList.remove('move-out-to-bottom');
            } else {
                image.classList.remove('move-in-from-bottom');
                image.classList.add('move-out-to-bottom');
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    let imageClassName = `hidden md:block max-w-1/3 max-h-[150%] absolute bottom-0 ${image_position === 'left' ? 'left-8' : 'right-8'} object-contain`;

    return (
        <Container className='bg-white rounded-t-4xl mt-16 md:mt-36 p-8 md:p-12 pb-5 md:pb-10 relative'>
            {image_position === 'left' && (
                <Image src={`${params.globals && params.globals.assets_url || ''}/${image}`} width={250} height={420} className={imageClassName} alt='Rene van Egmond' />
            )}            

            <div className='md:max-w-3/5'>
                {title && (
                    <Typography variant='h3' styleVariant='h2' className='text-black mb-2'>
                        {title}
                    </Typography>
                )}

                {text && (
                    <Typography variant='p'>
                        {text}
                    </Typography>
                )}

                {button_group && button_group.buttons && button_group.buttons.length > 0 && (
                    <div className="flex flex-wrap gap-2 md:gap-5 mt-5">
                        <Each of={button_group.buttons} render={(button, index) => (
                            <Button type={button.block_button_id.type} add_link_text={button.block_button_id.add_link_text} link_text={button.block_button_id.link_text} page={button.block_button_id.page} post={button.block_button_id.post} external_url={button.block_button_id.external_url} label={button.block_button_id.label} color={button.block_button_id.color} variant={button.block_button_id.variant} icon={button.block_button_id.icon} />
                        )} />
                    </div>
                )}
            </div>

            {image_position === 'right' && (
                <Image ref={imageRef} width={250} height={420} src={`${params.globals && params.globals.assets_url || ''}/${image}`} className={imageClassName} alt='Rene van Egmond' />
            )}
        </Container>
    );
}

export default BlockBanner;