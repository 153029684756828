import Schema from 'validate';

export const contactFormSchemaObj = {
    // website_type: {
    //     type: 'string',
    //     required: false,
    //     enum: ['interactive', 'business-card', 'e-commerce', 'custom'],
    //     message: 'website_type'
    // },
    // page_count: {
    //     type: 'number',
    //     required: false,
    //     size: {
    //         min: 1,
    //         max: 40
    //     },
    //     message: 'page_count'
    // },
    // phone: {
    //     type: 'string',
    //     required: true,
    //     match: /^\d{10,14}$/,
    //     message: 'phone'
    // },
    firstname: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Voornaam moet een string zijn.',
            required: 'Voornaam is verplicht.',
            length: 'Voornaam moet minimaal 2 karakters lang zijn.'
        }
    },
    lastname: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Achternaam moet een string zijn.',
            required: 'Achternaam is verplicht.',
            length: 'Achternaam moet minimaal 2 karakters lang zijn.'
        }
    },
    email: {
        type: 'string',
        required: true,
        match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: {
            type: 'E-mail moet een string zijn.',
            required: 'E-mail is verplicht.',
            match: 'E-mail moet een geldig e-mailadres zijn.'
        }
    },
    phonenumber: {
        type: 'string',
        required: true,
        match: /^\d{10,14}$/,
        message: 'phone'
    },
    message: {
        type: 'string',
        required: true,
        length: {
            min: 10
        },
        message: {
            type: 'Bericht moet een string zijn.',
            required: 'Bericht is verplicht.',
            length: 'Bericht moet minimaal 10 karakters lang zijn.'
        }
    }
};

export const contactFormSchema = new Schema(contactFormSchemaObj);




export const appointmentFormSchemaObj = {
    // website_type: {
    //     type: 'string',
    //     required: false,
    //     enum: ['interactive', 'business-card', 'e-commerce', 'custom'],
    //     message: 'website_type'
    // },
    // page_count: {
    //     type: 'number',
    //     required: false,
    //     size: {
    //         min: 1,
    //         max: 40
    //     },
    //     message: 'page_count'
    // },
    // phone: {
    //     type: 'string',
    //     required: true,
    //     match: /^\d{10,14}$/,
    //     message: 'phone'
    // },
    firstname: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Voornaam moet een string zijn.',
            required: 'Voornaam is verplicht.',
            length: 'Voornaam moet minimaal 2 karakters lang zijn.'
        }
    },
    lastname: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Achternaam moet een string zijn.',
            required: 'Achternaam is verplicht.',
            length: 'Achternaam moet minimaal 2 karakters lang zijn.'
        }
    },
    email: {
        type: 'string',
        required: true,
        match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: {
            type: 'E-mail moet een string zijn.',
            required: 'E-mail is verplicht.',
            match: 'E-mail moet een geldig e-mailadres zijn.'
        }
    },
    phonenumber: {
        type: 'string',
        required: true,
        match: /^\d{10,14}$/,
        message: 'phone'
    },
    street: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Straat moet een string zijn.',
            required: 'Straat is verplicht.',
            length: 'Straat moet minimaal 2 karakters lang zijn.'
        }
    },
    number: {
        type: 'string',
        required: true,
        message: {
            type: 'Huisnummer moet een string zijn.',
            required: 'Huisnummer is verplicht.'
        }
    },
    city: {
        type: 'string',
        required: true,
        length: {
            min: 2
        },
        message: {
            type: 'Plaats moet een string zijn.',
            required: 'Plaats is verplicht.',
            length: 'Plaats moet minimaal 2 karakters lang zijn.'
        }
    },
    'date-time': {
        type: 'string',
        required: true,
        message: {
            type: 'Datum / tijd moet een string zijn.',
            required: 'Datum / tijd is verplicht.'
        }
    },
    problem: {
        type: 'string',
        required: true,
        length: {
            min: 10
        },
        message: {
            type: 'Bericht moet een string zijn.',
            required: 'Bericht is verplicht.',
            length: 'Bericht moet minimaal 10 karakters lang zijn.'
        }
    }
};

export const appointmentFormSchema = new Schema(appointmentFormSchemaObj);
