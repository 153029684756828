import React from 'react';

import Image from 'next/image';

import { Container, Typography, Form } from '@/components';

const BlockFormSection = ({ title, image_position = 'left', image, form, params }) => {
    let imageClassName = 'w-auto md:w-full col-span-1 md:col-span-2 rounded-2xl';

    return (
        <Container className='grid grid-cols-1 md:grid-cols-5 gap-5 md:gap-20'>
            {image_position === 'left' && (
                <Image width={416} height={500} src={`${params.globals.assets_url || ''}/${image}`} alt={title} className={imageClassName} />
            )}

            <div className='max-w-lg col-span-1 md:col-span-3'>
                {title && (
                    <Typography variant='h2' className='text-black mb-2'>
                        {title}
                    </Typography>
                )}

                <Form form={form} />
            </div>

            {image_position === 'right' && (
                <Image width={416} height={500} src={`${params.globals.assets_url || ''}/${image}`} alt={title} className={imageClassName} />
            )}
        </Container>
    );
}

export default BlockFormSection;