import React from 'react';

import Link from 'next/link';

import * as lucideIcons from 'lucide-react';

import { Typography } from '@/components';

const Button = ({ type, add_link_text = false, link_text, page, post, external_url, label, color, variant, icon, className = '' }) => {
    let buttonClassName = `w-fit button hover:scale-105 transition-all duration-300 flex items-center ${color} ${variant}`;

    return (
        <div className='flex flex-col gap-2'>
            {type === 'pages' ? (
                <Link href={page.permalink} className={`${className} ${buttonClassName}`}>
                    {icon && (
                        <span className='mr-2'>
                            {React.createElement(lucideIcons[icon], { size: 22 })}
                        </span>
                    )}

                    {label}
                </Link>
            ) : type === 'posts' ? (
                <Link href={`/posts/${post.slug}`} className={`${className} ${buttonClassName}`}>
                    {icon && (
                        <span className='mr-2'>
                            {React.createElement(lucideIcons[icon], { size: 22 })}
                        </span>
                    )}

                    {label}
                </Link>
            ) : type === 'external' ? (
                <Link href={external_url} className={`${className} ${buttonClassName}`}>
                    {icon && (
                        <span className='mr-2'>
                            {React.createElement(lucideIcons[icon], { size: 22 })}
                        </span>
                    )}

                    {label}
                </Link>
            ) : type === 'submit' ? (
                <button type='submit' className={`${className} ${buttonClassName}`}>
                    {icon && (
                        <span className='mr-2'>
                            {React.createElement(lucideIcons[icon], { size: 22 })}
                        </span>
                    )}

                    {label}
                </button>
            ) : null}

            {add_link_text && (
                <Typography variant='p' className='text-sm break-all'>
                    {link_text}
                </Typography>   
            )}
        </div>
    );
}

export default Button;