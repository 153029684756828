import React from 'react';

const Trustoo = () => {
    return (
        <>
            <iframe className='hidden md:flex w-full md:w-[375px] justify-center' src="/trustoo.html"></iframe>
            <iframe className='flex md:hidden w-full md:w-[375px] h-[250px] justify-center' src="/trustoo-mobile.html"></iframe>
        </>
    );
}

export default Trustoo;